import React from "react";
import Slider from "../components/Slider";
import Testmoniral from "../components/Testmoniral";
import AboutUs from "../components/AboutUs";
import Work from "../components/Work";
import Care from "../components/Care";
import HowWork from "../components/HowWork";
import Professional from "../components/Professional";

export default function Home() {
  return (
    <div>
      <Slider />
      <Professional />
      <AboutUs />
      <Work />
      <Care />
      <HowWork />
      <Testmoniral />
    
    </div>
  );
}
