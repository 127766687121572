import React from 'react'
// import { FaTimes } from "react-icons/fa";
import { FaFacebookMessenger } from "react-icons/fa";
// import { BsGithub } from "react-icons/bs";
// import { BsFillPersonPlusFill } from "react-icons/bs";

export default function Message() {
  return (
    <div className=" px-2 flex z-30 fixed flex-col top-[90%]  right-0">
      <ul className=" flex  flex-col gap-4 px-2">
        <li className="bg-primary-500 hover:bg-primary-450    border  border-primary-100 text-white font-semibold px-3 py-3 rounded-full hover:scale-105 duration-500 cursor-pointer">
          <a
            className=" "
            href="https://www.facebook.com/profile.php?id=100063787490939 "
            target="_blank"
            rel="noreferrer"
          >
           <FaFacebookMessenger  size={25}/>
          </a>
        </li>
        {/* <li className="">
          <a
            className=" flex justify-between items-center w-full text-gray-300"
            href="https://github.com/rajan-shahi"
            target="_blank" rel="noreferrer"
          >
            Github <BsGithub size={30} />
          </a>
        </li> */}
      
        {/* <li className=" ">
          <a
            className=" flex justify-between items-center w-full text-gray-300"
            href="/"
            rel="noreferrer"   target="_blank"
          >
            Resume <BsFillPersonPlusFill size={30} />
          </a>
        </li> */}
      </ul>
    </div>
  )
}
