import oldman11 from "../assets/oldman/oldman11.jpg";
import testLogo from "../assets/logo/testlogo.png";
import video1 from "../video/video1.mp4";

export default function Testmoniral() {
  return (
    <div className=" md:px-0 h-full md:h-[60vh] px-4  bg-primary-550 flex justify-center">
      <div className=" md:flex  justify-center items-center gap-32  w-full md:w-10/12">
        <div className=" md:mt-0 mt-10">
          <img
            className=" rounded-xl  relative h-96 w-96 cursor-pointer object-cover"
            src={oldman11}
            alt=""
          />
          <img
            className=" md:-mt-28 -mt-20  md:-ml-10 -ml-3 absolute h-14 md:h-20 w-14 md:w-20"
            src={testLogo}
            alt=""
          />
        </div>
        <div className=" md:mt-0 mt-12">
          <video width="500" height="360" controls>
            <source src={video1} type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  );
}
