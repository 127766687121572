import Footer from "./components/Footer";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Navbar from "./components/Navbar";
import AboutUs from "./pages/AboutUs";
import Gallery from "./pages/Gallery";
import CareHome from "./pages/CareHome";
import Video from "./pages/Video";
import Program from "./pages/Program";
import Message from "./components/Message";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Navbar />
        <div className=" md:py-20 py-14">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/AboutUs" element={<AboutUs />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/carehome" element={<CareHome />} />
            <Route path="/video" element={<Video />} />
            <Route path="/program" element={<Program />} />
          </Routes>
        </div>
      </BrowserRouter>
      <Message />
      <Footer />
    </div>
  );
}

export default App;
