import React from "react";
import { LiaStarSolid } from "react-icons/lia";
import nurshing from "../assets/aboutUs/nurshing.png";
import medical from "../assets/aboutUs/medical.png";
import checkup from "../assets/aboutUs/checkup.png";
import oldman11 from "../assets/oldman/oldman11.jpg";
import oldman17 from "../assets/oldman/oldman17.jpg";

export default function Professional() {
  return (
    <div className=" md:px-0 md:py-0 py-6 h-full md:h-[100vh] px-4   bg-gray-100 flex items-center justify-center">
      <div className=" md:flex   justify-between items-center  w-full md:w-10/12">
        <div className=" flex flex-col gap-8 md:w-5/12">
          <div className="  md:mt-0 mt-4 flex gap-2">
            <span className="  text-primary-500">
              <LiaStarSolid size={22} />
            </span>
            <h1 className="  text-primary-350 font-bold">ABOUT US</h1>
          </div>
          <div className="  md:text-5xl text-2xl   text-gray-800 font-bold">
            We Make a Difference in Your Lives & Help
          </div>

          <div className=" grid grid-cols-1   gap-10">
            {works.map((work, index) => (
              <div
                key={index}
                className=" cursor-pointer  md:flex gap-8 items-center"
              >
                <img className="" src={work.img} alt="" />
                <div className=" flex  flex-col gap-2">
                  <span className=" text-xl    text-primary-350 font-bold">
                    {work.work}
                  </span>
                  <span className=" text-md text-gray-600">{work.desc}</span>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className=" ">
          <img
            className=" md:my-0 my-5  md:relative md:h-[60vh] md:w-[30vw] rounded-2xl"
            src={oldman11}
            alt=""
          />
          <img
            className=" md:h-[32vh] md:w-[18vw]  rounded-2xl md:absolute md:-mt-40  md:-ml-10 "
            src={oldman17}
            alt=""
          />
          <div className=" md:flex hidden flex-col -mt-96   ml-80 absolute items-center justify-center w-max z-40 rounded-2xl px-4 -rotate-6 py-16 hover:rotate-0 duration-500 bg-primary-400 ">
            <span className=" text-2xl   text-gray-100 font-bold">
              Mountain
            </span>
            <span className=" text-2xl  text-gray-100 font-bold">
              {" "}
              Care Home Service
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

const works = [
  {
    img: nurshing,
    work: "दीवा सेवा र रात्री सेवा दुवैको व्यवस्था",
    desc: "Day Service and Night Service refers to a provision of services or facilities available both during the day and depending on the context.",
  },
  {
    img: medical,
    work: "शारिरीक अश्वस्थ अनुसारको प्रोटिन युक्त खानाको व्यवस्था",
    desc: "Protein-rich food can be obtained from various sources such as meat, fish, eggs, dairy products, legumes, nuts, beans, and soy products.",
  },
  {
    img: checkup,
    work: "साधारण उपचार बेडसोर भएकाहरूको लागि पनि आवश्यकत अनुसार डा. को सुविधा",
    desc: " Doctors address bedsores through assessment, wound care, pain management, nutrition guidance, and preventive measures. They guide caregivers, monitor progress, and ensure a holistic approach for healing and prevention.",
  },
];
