import React from "react";
import { LiaStarSolid } from "react-icons/lia";
// import { CgProfile } from "react-icons/cg";
import { GrStatusGood } from "react-icons/gr";
// import { BsEmojiHeartEyes } from "react-icons/bs";
// import { BiHomeHeart } from "react-icons/bi";
import { Link } from "react-router-dom";

export default function Work() {
  return (
    <div className=" md:px-0 md:py-0 py-6 h-full md:h-[100vh] px-4  bg-gray-100 flex flex-col items-center justify-center">
      <div className=" md:flex  justify-between items-center  w-full md:w-10/12">
        <div className=" grid grid-cols-1 gap-10 md:grid-cols-2">
          {works.map((work, index) => (
            <div
              key={index}
              className="   md:w-80 cursor-pointer group flex flex-col gap-10  hover:bg-primary-400   bg-white duration-500 rounded-xl px-10 py-12"
            >
              <span className="  text-3xl text-white group-hover:bg-white group-hover:text-primary-400 bg-primary-400 w-max  rounded-xl p-4">
                {work.icon}
              </span>
              <div className=" flex flex-col gap-2">
                <span className="   text-gray-800 group-hover:text-gray-50 text-2xl font-bold">
                  {work.work}
                </span>
                <span className=" group-hover:text-gray-200 text-xl  text-gray-600">
                  {work.desc}
                </span>
              </div>
            </div>
          ))}
        </div>
        <div className=" flex flex-col gap-4 md:w-5/12">
          <div className="  md:mt-0 mt-4 flex gap-2">
            <span className=" text-primary-450">
              <LiaStarSolid size={22} />
            </span>
            <h1 className="  text-primary-350 font-bold">HOW IT'S WORK</h1>
          </div>
          <div className="  md:text-5xl text-2xl  text-gray-800 font-bold">
            The Mountain Care Home Service
          </div>
          <div className=" tracking-widest text-gray-600 text-xl font-semibold ">
            समाजमा रहेका जेष्ठ नागरिक अपांगता सुस्त मनस्थित भएका व्यक्तिहरू
            प्रति गर्ने नकारात्मक सोचले प्रयोग भईरहेका घिनलाग्दा प्रक्रियालाई
            सकारात्मक सोच र व्यवहारमा चाहिने उत्कृष्ठ स्याहार सम्महार र प्रोटीन
            यूक्त खाना चाहिने मनोबल उत्प्रेरणाको उद्देश्यक लागि समाजिक परिर्वतन
            हुनु हो ।
          </div>
          <Link
            to={"/contact"}
            className=" hover:scale-105 px-12 py-2 hover:bg-gray-500 duration-300   bg-primary-450 w-max text-gray-100 rounded-xl"
          >
            CONTACT US
          </Link>
        </div>
      </div>
    </div>
  );
}

const works = [
  {
    icon: <GrStatusGood />,
    work: "सुविधा",
    desc: "स्वच्छ र सफा वातावरण",
  },
  {
    icon: <GrStatusGood />,
    work: "सुविधा",
    desc: "दीवा सेवा र रात्री सेवा दुवैको व्यवस्था",
  },
  {
    icon: <GrStatusGood />,
    work: "सुविधा",
    desc: "शारिरीक अश्वस्थ अनुसारको प्रोटिन युक्त खानाको व्यवस्था",
  },
  {
    icon: <GrStatusGood />,
    work: "सुविधा",
    desc: "सुस्त मनष्धित व्यक्तित्वहरूको मनोवल बढाउन उत्प्रेरणा गर्ने ",
  },
];
