import React from "react";
import ai11 from "../assets/ai/ai1.jpg";
import { LiaStarSolid } from "react-icons/lia";
import care1 from "../assets/careImage/care1.png";
import care2 from "../assets/careImage/care2.png";
import care3 from "../assets/careImage/care3.png";

export default function Care() {
  return (
    <div className=" overflow-x-hidden flex  justify-center items-center flex-col">
      <div className=" md:h-[100vh] h-[55vh]  md:w-[100vw] relative bg-gradient-to-br  from-cyan-700    to-green-600  ">
        <img className="  mix-blend-overlay h-full w-full  object-cover" src={ai11} alt="" />
      </div>
      <div className=" z-20 md:-mt-40 absolute flex justify-center items-center md:w-9/12 w-11/12 flex-col gap-5">
        <div className=" flex gap-2">
          <span className="  text-red-700">
            <LiaStarSolid size={22} />
          </span>
          <h1 className="   text-white tracking-widest font-bold">
            CARE FOR EVERY SITUATION
          </h1>
        </div>
        <span className=" tracking-widest   md:text-5xl text-2xl     text-white z-10  italic font-bold">
          साधारण उपचार बेडसोर भएकाहरूको लागि पनि आवश्यकता अनुसार डा. को सुविधा
        </span>
      </div>
      <div className=" z-40  md:-mt-28  flex justify-center  bg-primary-550">
        <div className="  md:flex justify-center  bg-gray-100 border-t-4 items-center w-11/12 md:w-10/12">
          {cares.map((care, index) => (
            <div key={index}>
              <div className=" flex flex-col gap-4 -mt-16 py-10 px-8 bg-primary-450  md:border-t-4  md:border-gray-300">
                <div className="   flex items-center  justify-between py-5">
                  <span className="   text-white font-bold text-xl">
                    {care.work}
                  </span>
                  <img
                    className=" cursor-pointer object-cover h-14 w-14"
                    src={care.img}
                    alt=""
                  />
                </div>
                <span className=" md:flex hidden border border-gray-500   w-28"></span>
                <div className=" md:border-0 border-b-2 border-gray-500 py-2 text-gray-400 font-bold text-md">
                  {care.desc}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

const cares = [
  {
    img: care1,
    work: "लामो समय अस्पतालको सेवा लिनु पर्नेहरू",
    desc: "Those in need of long-term hospital care face prolonged health challenges, such as chronic illnesses or severe injuries, requiring extended medical services. This specialized care involves a dedicated team addressing both immediate and ongoing health needs for comprehensive recovery.",
  },
  {
    img: care2,
    work: "सेवा लिने व्यक्तिको लागि राख्ने बेलामा चाहिने खाई रहेको औषधि",
    desc: "Medication to be taken on an empty stomach This refers to a type of medication that should be consumed before meals or without any food intake, typically as per the prescribed instructions from a healthcare professional. Taking such medications on an empty stomach may enhance their absorption and effectiveness.",
  },
  {
    img: care3,
    work: "नागरिकताको फोटोकपी, साक्षी बस्नेको पनि ",
    desc: "In the context of official documents or processes, this phrase suggests obtaining a photocopy of citizenship and also having it attested or verified as authentic. This may be required for various purposes,  legal procedures, or other situations where proof of citizenship is necessary.",
  },
];
