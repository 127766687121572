import { LiaStarSolid } from "react-icons/lia";
import oldman17 from "../assets/oldman/oldman17.jpg";
// import oldman1 from "../assets/oldman/oldman1.jpg";
import car1 from "../assets/caresoalImage/car1.jpg";
import car2 from "../assets/caresoalImage/car2.jpg";
import car3 from "../assets/caresoalImage/car3.jpg";
import car4 from "../assets/caresoalImage/car4.jpg";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

import { Link } from "react-router-dom";

export default function Slider() {
  return (
    <div className="">
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        {sliderImages.map((sliderImage, index) => (
          <SwiperSlide>
            <div key={index} className=" ">
              <div className=" relative md:h-[100vh] md:w-[100vw] bg-gradient-to-br  from-cyan-700    to-green-600  h-[35vh] w-full flex justify-center items-center flex-col   ">
                <img
                  className="  absolute    w-full h-full mix-blend-overlay  object-cover"
                  src={sliderImage.img}
                  alt=""
                />
                <div className=" md:px-0 px-4 z-30 absolute md:w-7/12 md:-ml-10 flex   justify-center items-center flex-col gap-5">
                  <div className=" flex items-center gap-2">
                    <span className=" text-primary-500">
                      {sliderImage.icon}
                    </span>
                    <span className="  md:text-2xl text-xl  font-bold    text-white tracking-widest ">
                      {sliderImage.type}
                    </span>{" "}
                  </div>
                  <span className=" tracking-widest md:text-6xl text-xl     text-white font-bold flex justify-center items-center flex-col gap-1">
                    {sliderImage.desc}
                  </span>
                  <Link
                    to={"/contact"}
                    className=" md:flex hidden hover:bg-gray-500  duration-500 hover:scale-105   bg-primary-500 text-gray-100 rounded-xl px-14 text-xl py-2 w-max"
                  >
                    {sliderImage.btn}
                  </Link>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

const sliderImages = [
  {
    icon: <LiaStarSolid size={22} />,
    type: "Mountain Care Home Service",
    img: car3,
    desc: "शारिरीक अश्वस्थ अनुसारको प्रोटिन युक्त खानाको व्यवस्था",
    btn: "Contact Us",
  },
  {
    icon: <LiaStarSolid size={22} />,
    img: car1,
    type: "Mountain Care Home Service",
    desc: "शारिरीक अश्वस्थ अनुसारको प्रोटिन युक्त खानाको व्यवस्था ",
    btn: "Contact Us",
  },
  {
    icon: <LiaStarSolid size={22} />,
    img: car2,
    type: "Mountain Care Home Service",
    desc: "सुस्त मनष्धित व्यक्तित्वहरूको मनोवल बढाउन उत्प्रेरणा गर्ने ",
    btn: "Contact Us",
  },
  {
    icon: <LiaStarSolid size={22} />,
    type: "Mountain Care Home Service",
    img: car4,
    desc: "दीवा सेवा र रात्री सेवा दुवैको व्यवस्था ",
    btn: "Contact Us",
  },
  {
    icon: <LiaStarSolid size={22} />,
    type: "Mountain Care Home Service",
    img: oldman17,
    desc: "स्वच्छ र सफा वातावरण",
    btn: "Contact Us",
  },
  // {
  //   icon: <LiaStarSolid size={22} />,
  //   type: "Mountain Care Home Service",
  //   img: oldman1,
  //   desc: "सुस्त मनष्धित व्यक्तित्वहरूको मनोवल बढाउन उत्प्रेरणा गर्ने ",
  //   btn: "Contact Us",
];
