import React from "react";
import founder from "../assets/logo/founder.jpg";
import ceo from "../assets/aboutUs/ceo.jpg";

export default function Contact() {
  return (
    <div>
      <main className=" flex justify-center items-center py-14">
        <div className="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8">
          <div className="max-w-lg mx-auto gap-12 justify-between lg:flex lg:max-w-none">
            <div className="max-w-lg space-y-3">
              <h3 className="   text-primary-350 text-xl font-semibold">
                Contact
              </h3>
              <p className="   text-gray-800 text-3xl font-semibold sm:text-4xl">
                Let us know how we can help
              </p>
              <p className=" tracking-widest">
                २०७३ साल कर कार्यालय, कलंकीमा दर्ता भई नागार्जुन वडा नं. ५ तीन
                रोपनी जग्गामा बनेको भूई तल्ला भवन २ ब्लक छन् । राम्रो व्यवस्थापन
                र दक्ष जनशक्तिहरूबाट स्याहार सुसार उपलब्ध गराउने गरको जानकारी
                गराउँदछौ । लामो समयको अन्तरालमा धेरैजनाको अनुभव बटुली अशक्त
                अवस्थामा वहाँहरूको पिडालाई सकेको मलमहरू लगाएर आत्मा शान्ति गरी
                बाँचुन्जेल हाँसी खुसी राख्न सफल भएकोमा हामी गौरन्भावोत ठान्दछौं
                । आत्मा शान्ति नै जीन्दगीको ठूलो सफलता हो ।
              </p>
              <p className=" tracking-widest">
                अशक्त भएका, अस्पतालमा लामो समय बस्नु पर्ने (प्यारालाइसिस)
                अपांगता भएका हरुको लागि लामो अनुभव भएका अनुभवी र राम्रो हेरचाह
                गर्नुपर्ने आवश्यकता भएमा दिन को लागि र राति कोलाइ पनि ब्यब्स्था
                छ यो हेरचाह गर्ने केन्द्रिय कार्यालय नागार्जुन ५ सितापाइला मा
                सम्पर्क गर्नुहोस्
              </p>
              <div>
                <ul className="mt-6 flex flex-wrap gap-x-10 gap-y-6 items-center">
                  {contactMethods.map((item, idx) => (
                    <li key={idx} className="flex flex-col  gap-x-3">
                      <div className="flex-none  text-primary-350 cursor-pointer ">
                        {item.icon}
                      </div>
                      <p>{item.contact}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className=" flex justify-center items-center flex-col gap-5 flex-1 md:w-6/12">
              <div className=" gap-2 md:flex ">
                <div>
                  <img
                    className=" md:h-96 md:w-96  rounded-xl  object-cover"
                    src={founder}
                    alt=""
                  />
                  <h3 className=" md:mb-0 mb-3  text-gray-600 text-xl font-semibold">
                    Investor:-Swodesh Karki
                  </h3>
                </div>
                <div>
                  <img
                    className=" md:h-96 md:w-96  rounded-xl  object-cover"
                    src={ceo}
                    alt=""
                  />
                  <h3 className="  text-gray-600 text-xl font-semibold">
                    CEO:-Sita Parajuli
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className=" mt-10">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d226055.3753689543!2d85.16586604673762!3d27.713727963633225!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb2278002c4b6d%3A0xb3625a7dc9bc81d8!2z4KS44KS_4KSk4KS-4KSq4KS-4KSI4KSy4KS-IOCkruCkqOCljeCkpuCkv-CksA!5e0!3m2!1sen!2snp!4v1707902079710!5m2!1sen!2snp"
              className=" md:w-[1200px] md:h-[450px] h-96 w-80"
              title="map"
            ></iframe>
          </div>
        </div>
      </main>
    </div>
  );
}

const contactMethods = [
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-10 h-10"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
        />
      </svg>
    ),
    contact: "mchspl.info@gmail.com",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-10 h-10"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
        />
      </svg>
    ),
    contact: 9851311173,
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-10 h-10"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
        />
      </svg>
    ),
    contact: "Sitapaila-5, Buddha Tole, Kathmandu Nepal.",
  },
];
