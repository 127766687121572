import React from "react";
// import old1 from "../assets/oldage/old1.jpg";
// import old2 from "../assets/oldage/old2.jpg";
// import old3 from "../assets/oldage/old3.jpg";
// import old4 from "../assets/oldage/old4.jpg";
// import old5 from "../assets/oldage/old5.jpg";
// import old6 from "../assets/oldage/old6.jpg";
// import car1 from "../assets/caresoalImage/car1.jpg";
// import car2 from "../assets/caresoalImage/car2.jpg";

import gal1 from "../assets/oldage/gal1.jpg";
import gal2 from "../assets/oldage/gal2.jpg";
import gal3 from "../assets/oldage/gal3.jpg";
import gal7 from "../assets/oldage/gal7.jpg";
import gal8 from "../assets/oldage/gal8.jpg";
import oldman8 from "../assets/oldman/oldman8.jpg";
import oldman10 from "../assets/oldman/oldman10.jpg";
import oldman11 from "../assets/oldman/oldman11.jpg";
import oldman12 from "../assets/oldman/oldman12.jpg";
import oldman13 from "../assets/oldman/oldman13.jpg";
import oldman14 from "../assets/oldman/oldman14.jpg";
import oldman17 from "../assets/oldman/oldman17.jpg";
import phota1 from "../assets/oldage/phota1.jpg";
import phota2 from "../assets/oldage/phota2.jpg";
import phota3 from "../assets/oldage/phota3.jpg";
import phota5 from "../assets/oldage/phota5.jpg";

export default function Gallery() {
  return (
    <div>
      <div className="  grid md:grid-cols-3 sm:grid-cols-2  lg:grid-cols-4">
        {oldmanImage.map((oldmanImage, index) => (
          <div key={index} className="">
            <img
              className=" h-96 w-96 object-cover cursor-pointer hover:scale-105 duration-500"
              src={oldmanImage.img}
              alt=""
            />
          </div>
        ))}
      </div>
    </div>
  );
}

const oldmanImage = [
  {
    img: phota2,
  },
  {
    img: phota3,
  },

  {
    img: phota5,
  },

  {
    img: gal2,
  },

  {
    img: gal3,
  },

  

  {
    img: gal7,
  },

  {
    img: gal8,
  },

  {
    img: gal1,
  },
  {
    img: phota1,
  },

  {
    img: oldman14,
  },
  {
    img: oldman13,
  },

  {
    img: oldman8,
  },
  {
    img: oldman17,
  },
  {
    img: oldman11,
  },

  {
    img: oldman10,
  },

  {
    img: oldman12,
  },
];
