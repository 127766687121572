import React from "react";
// import about from "../assets/caresoalImage/about.jpg";
import { LiaStarSolid } from "react-icons/lia";
import nurshing from "../assets/aboutUs/nurshing.png";
import medical from "../assets/aboutUs/medical.png";
import checkup from "../assets/aboutUs/checkup.png";
import oldman1 from "../assets/oldman/oldman1.jpg";
import ai10 from "../assets/ai/ai10.jpg";

export default function CareHome() {
  return (
    <div className=" md:px-0 h-full px-4   bg-gray-100 flex justify-center">
      <div className="  w-full py-10 md:w-10/12">
        <div className=" md:flex  justify-between">
          <div className=" flex flex-col gap-4 md:w-5/12">
            <div className="  md:mt-0 mt-4 flex gap-2">
              <span className="  text-primary-500">
                <LiaStarSolid size={22} />
              </span>
              <h1 className="  text-primary-350 font-bold">सुविधाहरु</h1>
            </div>
            <div className="  md:text-5xl text-2xl    text-gray-800 font-bold">
              The Mountain Care Home Service
            </div>
            <div className="  tracking-widest text-gray-600 text-md ">
              समाजमा रहेका जेष्ठ नागरिक अपांगता सुस्त मनस्थित भएका व्यक्तिहरू
              प्रति गर्ने नकारात्मक सोचले प्रयोग भईरहेका घिनलाग्दा प्रक्रियालाई
              सकारात्मक सोच र व्यवहारमा चाहिने उत्कृष्ठ स्याहार सम्महार र
              प्रोटीन यूक्त खाना चाहिने मनोबल उत्प्रेरणाको उद्देश्यक लागि समाजिक
              परिर्वतन हुनु हो
            </div>
          </div>
          <div className=" md:w-5/12">
            <img
              className=" md:h-[60vh] rounded-2xl cursor-pointer object-cover"
              src={ai10}
              alt=""
            />
          </div>
        </div>
        <div className=" md:flex justify-between  mt-10 md:w-10/12">
          {/* button */}
          <div className=" md:md:w-5/12">
            <img
              className=" md:h-[60vh] rounded-2xl cursor-pointer object-cover"
              src={oldman1}
              alt=""
            />
          </div>
          <div className="  w-full md:w-5/12 grid grid-cols-1 md:pt-0 pt-4   gap-10">
            {works.map((work, index) => (
              <div
                key={index}
                className=" cursor-pointer  md:flex gap-8 items-center"
              >
                <img className=" cursor-pointer object-cover " src={work.img} alt="" />
                <div className=" flex  flex-col gap-2">
                  <span className=" text-xl   text-primary-350 font-bold">
                    {work.work}
                  </span>
                  <span className=" text-md text-gray-600">{work.desc}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

const works = [
  {
    img: nurshing,
    work: " शारिरीक अश्वस्थ अनुसारको प्रोटिन युक्त खानाको व्यवस्था ",
    desc: "Protein-rich food can be obtained from various sources such as meat, fish, eggs, dairy products, legumes, nuts, beans, and soy products.",
  },
  {
    img: medical,
    work: "सुस्त मनष्धित व्यक्तित्वहरूको मनोवल बढाउन उत्प्रेरणा गर्ने",
    desc: "Motivating individuals with low morale to enhance their mental well-being This suggests the act of inspiring or encouraging people who may be experiencing a lack of enthusiasm or low spirits to improve their mental state.",
  },
  {
    img: checkup,
    work: "साधारण उपचार बेडसोर भएकाहरूको लागि पनि आवश्यकता अनुसार डा. को सुविधा",
    desc: "This phrase suggests that doctors provide facilities and treatments tailored to the specific needs of individuals suffering from bedsores. It emphasizes the importance of customized care based on the severity and condition of bedsores.",
  },
];
