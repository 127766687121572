import React, { useState } from "react";
import mountain from "../assets/logo/mountain.jpg";
import { useLocation } from "react-router-dom";

export default function Navbar() {
  const [state, setState] = useState(false);
  const [nav, setNav] = useState(false);
  const handleclick = () => setNav(!nav);

  const location = useLocation();
  console.log(location.pathname);

  const navigation = [
    { title: "Home", path: "/" },
    { title: "About Us", path: "/AboutUs" },
    { title: "Health Care", path: "/carehome" },
    { title: "Gallery", path: "/gallery" },
    { title: "Our Program", path: "/program" },
    { title: "video", path: "/video" },
    { title: "Contact Us", path: "/contact" },
  ];

  return (
    <div className=" z-50  fixed left-0 right-0 top-0 border-b-2 border-gray-250  bg-white">
      <div className="  md:flex hidden justify-between px-10 py-5 items-center  ">
        <a href={"/"}>
          <img
            className=" h-10 w-44 text-primary-500  object-cover"
            src={mountain}
            alt=""
          />
        </a>
        <div className=" flex gap-8  px-20    text-gray-600 text-[21px]">
          <a
            href={"/"}
            className={`${
              location.pathname === "/" ? " text-primary-350" : ""
            }  hover:text-primary-450 duration-300  font-bold cursor-pointer`}
          >
            Home
          </a>
          <a
            href={"/AboutUs"}
            className={`${
              location.pathname === "/AboutUs" ? " text-primary-350" : ""
            }  hover:text-primary-450 duration-300  font-bold cursor-pointer`}
          >
            About Us
          </a>

          <a
            href={"/gallery"}
            className={`${
              location.pathname === "/gallery" ? " text-primary-350" : ""
            }  hover:text-primary-450 duration-300  font-bold cursor-pointer`}
          >
            Gallery
          </a>
          <a
            href={"/carehome"}
            className={`${
              location.pathname === "/carehome" ? "text-primary-350" : ""
            }  hover:text-primary-450 duration-300  font-bold cursor-pointer`}
          >
            Health Care
          </a>
          <a
            href={"/program"}
            className={`${
              location.pathname === "/program" ? " text-primary-3500" : ""
            }  hover:text-primary-450 duration-300  font-bold cursor-pointer`}
          >
            Our Program
          </a>

          <a
            href={"/video"}
            className={`${
              location.pathname === "/video" ? " text-primary-350" : ""
            }  hover:text-primary-450 duration-300  font-bold cursor-pointer`}
          >
            Video
          </a>
          <a
            href={"/contact"}
            className={`${
              location.pathname === "/contact" ? " text-primary-3500" : ""
            }  hover:text-primary-450 duration-300  font-bold cursor-pointer`}
          >
            Contact Us
          </a>
        </div>
      </div>
      {/* mobile menu */}
      <div className=" md:hidden flex">
        <nav className="bg-white w-full border-b md:border-0 md:static">
          <div className="items-center px-4 max-w-screen-xl mx-auto md:flex md:px-8">
            <div className="flex items-center justify-between py-3 md:py-5 md:block">
              <a href="/">
                <img className=" h-12 object-cover" src={mountain} alt="" />
              </a>
              <div onClick={handleclick} className="md:hidden">
                <button
                  className="text-gray-700 outline-none p-2 rounded-md focus:border-gray-400 focus:border"
                  onClick={() => setState(!state)}
                >
                  {state ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M4 8h16M4 16h16"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
            <div
              className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 ${
                state ? "block" : "hidden"
              }`}
            >
              <ul className="justify-center items-center space-y-6 md:flex md:space-x-6 md:space-y-0">
                {navigation.map((item, idx) => {
                  return (
                    <li
                      key={idx}
                      className="text-gray-600   text-[20px]  hover:text-primary-400 duration-300"
                    >
                      <a href={item.path}>{item.title}</a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </nav>
        {nav ? (
          <div className=" bg-black/80 fixed w-full h-screen z-10  top-[470px]  left-0"></div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
