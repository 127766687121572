import React from "react";
import { LiaStarSolid } from "react-icons/lia";
// import { CgProfile } from "react-icons/cg";
// import { GrStatusGood } from "react-icons/gr";
// import { BsEmojiHeartEyes } from "react-icons/bs";
// import { BiHomeHeart } from "react-icons/bi";
import oldman11 from "../assets/oldman/oldman11.jpg";
import oldman17 from "../assets/oldman/oldman17.jpg";

export default function AboutUs() {
  return (
    <div className=" md:px-0 md:py-0 py-6 h-full md:h-[100vh] px-4   flex items-center justify-center">
      <div className=" md:flex   justify-between items-center  w-full md:w-10/12">
        <div className=" flex flex-col gap-8 md:w-6/12">
          <div className="  md:mt-0 mt-4 flex gap-2">
            <span className="   text-primary-500">
              <LiaStarSolid size={22} />
            </span>
            <h1 className=" text-primary-350 font-bold">परिचय</h1>
          </div>
          <div className="   tracking-widest  text-gray-800 font-bold">
            २०७३ साल कर कार्यालय, कलंकीमा दर्ता भई नागार्जुन वडा नं. ५ तीन रोपनी
            जग्गामा बनेको भूई तल्ला भवन २ ब्लक छन् । राम्रो व्यवस्थापन र दक्ष
            जनशक्तिहरूबाट स्याहार सुसार उपलब्ध गराउने गरको जानकारी गराउँदछौ ।
            लामो समयको अन्तरालमा धेरैजनाको अनुभव बटुली अशक्त अवस्थामा वहाँहरूको
            पिडालाई सकेको मलमहरू लगाएर आत्मा शान्ति गरी बाँचुन्जेल हाँसी खुसी
            राख्न सफल भएकोमा हामी गौरन्भावोत ठान्दछौं । आत्मा शान्ति नै
            जीन्दगीको ठूलो सफलता हो ।
          </div>
          <div className=" tracking-widest  text-gray-600 text-md  font-bold">
            समाजमा रहेका जेष्ठ नागरिक अपांगता सुस्त मनस्थित भएका व्यक्तिहरू
            प्रति गर्ने नकारात्मक सोचले प्रयोग भईरहेका घिनलाग्दा प्रक्रियालाई
            सकारात्मक सोच र व्यवहारमा चाहिने उत्कृष्ठ स्याहार सम्महार र प्रोटीन
            यूक्त खाना चाहिने मनोबल उत्प्रेरणाको उद्देश्यक लागि समाजिक परिर्वतन
            हुनु हो ।
            <span className="  text-primary-400 underline">
              कस्तो व्यक्तिको लागि सेवा लिन सकिन्छ
            </span>
          </div>

          <div className=" grid grid-cols-1 md:grid-cols-2  gap-10">
            {works.map((work, index) => (
              <div
                key={index}
                className=" cursor-pointer  flex gap-4 items-center"
              >
                <span className=" text-2xl  text-gray-800">{work.icon}</span>
                <div className=" flex  gap-2">
                  <span className=" text-xl   text-primary-350 font-bold">
                    {work.work}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className=" ">
          <img
            className=" md:my-0 my-5  md:relative md:h-[60vh] md:w-[30vw] rounded-2xl"
            src={oldman11}
            alt=""
          />
          <img
            className=" md:h-[32vh] md:w-[18vw] rounded-2xl md:absolute md:-mt-40 md:-rotate-6 md:-ml-10 hover:rotate-0 duration-500"
            src={oldman17}
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

const works = [
  {
    icon: 1,
    work: "अपांगता भएका जुनसुकै उमेरका व्यक्ति",
  },
  {
    icon: 2,
    work: " जेष्ठ नागरिक ६० वर्ष कटेका",
  },
  {
    icon: 3,
    work: "घरमा स्याहार सुसार गर्न नभ्याउनेहरू ",
  },
  {
    icon: 4,
    work: "लामो समय अस्पतालको सेवा लिनु पर्नेहरू",
  },
  {
    icon: 5,
    work: "सेवा लिने व्यक्तिको लागि राख्ने बेलामा चाहिने",
  },
  {
    icon: 6,
    work: "साक्षी बस्ने व्यक्ति आफन्त",
  },
];
