import React from "react";
import video15 from "../video/video15.mp4";
import program2 from "../assets/programs/program2.jpg";
import program3 from "../assets/programs/program3.jpg";
import program4 from "../assets/programs/program4.jpg";
import program5 from "../assets/programs/program5.jpg";
import program6 from "../assets/programs/program6.jpg";
import program7 from "../assets/programs/program7.jpg";
import program8 from "../assets/programs/program8.jpg";
import program9 from "../assets/programs/program9.jpg";

const Program = () => {
  return (
    <div className=" flex flex-col justify-center items-center ">
      <div className="  grid md:grid-cols-3 sm:grid-cols-2  lg:grid-cols-4">
        {images.map((image, index) => (
          <div key={index} className="">
            <img
              className=" h-96 w-96 object-cover cursor-pointer hover:scale-105 duration-500"
              src={image.img}
              alt=""
            />
          </div>
        ))}
      </div>
      <div>
        <video width="500" height="360" controls>
          <source src={video15} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export default Program;

const images = [
  {
    img: program2,
  },
  {
    img: program3,
  },
  {
    img: program4,
  },
  {
    img: program5,
  },
  {
    img: program6,
  },
  {
    img: program7,
  },
  {
    img: program8,
  },
  {
    img: program9,
  },
];
