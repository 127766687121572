import React from "react";
import { LiaStarSolid } from "react-icons/lia";

export default function HowWork() {
  return (
    <div className=" bg-primary-550 border-b border-gray-700 py-10 flex justify-center">
      <div className=" flex flex-col justify-center items-center gap-4 md:px-0 px-4 w-full md:w-9/12">
        <div className=" flex gap-2">
          <span className="  text-primary-400">
            <LiaStarSolid size={22} />
          </span>
          <h1 className="  text-gray-100 font-bold">HOW IT'S WORK</h1>
        </div>
        <div className=" tracking-widest md:text-7xl text-2xl   text-gray-100 font-bold flex md:justify-center md:items-center flex-col gap-1">
          <span className=" ">We Take Care Senior Person</span>
          <span>Who Generally Alone</span>
        </div>
      </div>
    </div>
  );
}
