import React from "react";
import video8 from "../video/video8.mp4";
import video9 from "../video/video9.mp4";
import video2 from "../video/video2.mp4";
import video4 from "../video/video4.mp4";
import video5 from "../video/video5.mp4";
import video6 from "../video/video6.mp4";
import video10 from "../video/video10.mp4";
import video13 from "../video/video13.mp4";

export default function Video() {
  return (
    <div className=" flex  gap-10 justify-center items-center flex-wrap ">
      <video width="500" height="360" controls>
        <source src={video9} type="video/mp4" />
      </video>
      <video width="500" height="360" controls>
        <source src={video8} type="video/mp4" />
      </video>
      <video width="500" height="360" controls>
        <source src={video10} type="video/mp4" />
      </video>
      <video width="500" height="360" controls>
        <source src={video2} type="video/mp4" />
      </video>
      <video width="500" height="360" controls>
        <source src={video4} type="video/mp4" />
      </video>
      <video width="500" height="360" controls>
        <source src={video5} type="video/mp4" />
      </video>
      <video width="500" height="360" controls>
        <source src={video6} type="video/mp4" />
      </video>
      <video width="300" height="160" controls>
        <source src={video13} type="video/mp4" />
      </video>
    </div>
  );
}
