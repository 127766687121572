import React from "react";

export default function Footer() {
  return (
    <div className=" md:px-0 px-4  bg-primary-550">
      <footer className="text-gray-500  md:py-14 py-8  md:w-10/12 w-full mx-auto">
        <div className="gap-6 justify-between md:flex">
          <div className="flex-1 flex flex-col">
            <div className=" md:flex">
              <div className=" flex flex-col  gap-2 flex-1">
                <span className="  tracking-widest md:text-4xl text-2xl    text-gray-100 font-bold">
                  Mountain Care Home Service
                </span>
                <span className="   text-gray-400 font-bold ">
                  <span className="  text-primary-350 "> Email address :- </span>{" "}
                  mchspl.info@gmail.com
                </span>
                <span className="   text-gray-400 font-bold ">
                  <span className=" text-primary-350 ">Location :- </span>{" "}
                  Sitapaila-5, Buddha Tole, Kathmandu Nepal
                </span>
              </div>
              <div className=" md:mt-0 mt-5 flex-1">
                <span className=" tracking-widest  md:text-4xl text-2xl   text-gray-100 font-bold">
                  हेरचाह स्याहार गर्ने केन्द्र
                </span>
                <p className=" tracking-widest  leading-relaxed mt-5 text-[15px]">
                  अशक्त भएका, अस्पतालमा लामो समय बस्नु पर्ने (प्यारालाइसिस)
                  अपांगता भएका हरुको लागि लामो अनुभव भएका अनुभवी र राम्रो हेरचाह
                  गर्नुपर्ने आवश्यकता भएमा दिन को लागि र राति कोलाइ पनि
                  ब्यब्स्था छ यो हेरचाह गर्ने केन्द्रिय कार्यालय नागार्जुन ५
                  सितापाइला मा सम्पर्क गर्नुहोस्
                </p>{" "}
              </div>
            </div>
            <div>
              <div className="  text-gray-400 font-bold  text-xl max-w-sm flex items-center">
                <span className=" md:flex hidden text-primary-350 "> phone :- </span> 9849009605, 9851311173
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8  border-t  border-gray-600 items-center justify-between sm:flex">
          <div className="mt-4 sm:mt-0">
            &copy;  2024 Designed and Developed by {" "}
            <a
              className=" text-green-600 border-b border-gray-500"
              target="_blank"
              rel="noreferrer"
              href="https://www.cloudsnepalweb.com/"
            >
              {" "}
             Clouds Nepal Web Pvt.Ltd
            </a>
          </div>
          <div className="mt-6 sm:mt-0">
            <ul className="flex items-center  space-x-4">
              <li className="w-10 md:mt-2 h-10 border rounded-full flex items-center justify-center">
                <a rel="noreferrer" 
                  href="https://www.facebook.com/profile.php?id=100063787490939"
                  target="_blank"
                >
                  <svg
                    class="svg-icon w-6 h-6 text-green-500"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fill="none"
                      d="M11.344,5.71c0-0.73,0.074-1.122,1.199-1.122h1.502V1.871h-2.404c-2.886,0-3.903,1.36-3.903,3.646v1.765h-1.8V10h1.8v8.128h3.601V10h2.403l0.32-2.718h-2.724L11.344,5.71z"
                    ></path>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <style jsx>{`
          .svg-icon path,
          .svg-icon polygon,
          .svg-icon rect {
            fill: currentColor;
          }
        `}</style>
      </footer>
    </div>
  );
}
