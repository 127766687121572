import React from "react";
// import about from "../assets/caresoalImage/about.jpg";
import { LiaStarSolid } from "react-icons/lia";
import oldman1 from "../assets/oldman/oldman1.jpg";

export default function AboutUs() {
  return (
    <div className=" md:px-0 h-full md:h-[80vh] px-4   bg-primary-550  flex justify-center">
      <div className=" md:flex  justify-between  mt-14  w-full md:w-10/12">
        <div className=" md:w-4/12">
          <img
            className=" md:h-[65vh] rounded-2xl cursor-pointer object-cover"
            src={oldman1}
            alt=""
          />
        </div>
        <div className=" flex flex-col gap-4 md:w-6/12">
          <div className="  md:mt-0 mt-4 flex gap-2">
            <span className=" text-primary-450">
              <LiaStarSolid size={22} />
            </span>
            <h1 className="  text-primary-350 font-bold">ABOUT US</h1>
          </div>
          <div className="  md:text-5xl text-2xl  text-gray-100 font-bold">
          Mountain Care Home Service 
          </div>
          <div className=" tracking-widest  text-gray-400 text-xl font-semibold ">
          २०७३ साल कर कार्यालय, कलंकीमा दर्ता भई नागार्जुन वडा नं. ५ तीन रोपनी जग्गामा बनेको भूई तल्ला भवन २ ब्लक छन् । राम्रो व्यवस्थापन र दक्ष जनशक्तिहरूबाट स्याहार सुसार उपलब्ध गराउने गरको जानकारी गराउँदछौ । लामो समयको अन्तरालमा धेरैजनाको अनुभव बटुली अशक्त अवस्थामा वहाँहरूको पिडालाई सकेको मलमहरू लगाएर आत्मा शान्ति गरी बाँचुन्जेल हाँसी खुसी राख्न सफल भएकोमा हामी गौरन्भावोत ठान्दछौं । आत्मा शान्ति नै जीन्दगीको ठूलो सफलता हो
          </div>
          <div className="  md:text-2xl text-xl  md:mb-0 mb-4  text-gray-300  font-bold">
            8+ YEARS OF EXPERIENCE & TRUSTED CARE SERVICE
          </div>
        </div>
      </div>
    </div>
  );
}
